"use client";

import { Theme } from "@radix-ui/themes";
import styled from "styled-components";

const StyledTheme = styled(Theme)`
  /* Parents nodes must have height "100%" to takes up the full viewport available  */
  height: 100%;
  /* Override the default color to use the IC black */
  color: var(--black);
  --container-3: 1200px;
  --container-4: 1600px;
`;

function ICTheme({
  children,
  appearance = "light",
}: {
  children: React.ReactNode;
  appearance?: "light" | "dark";
}) {
  return (
    <StyledTheme accentColor="mint" appearance={appearance}>
      <div style={{ isolation: "isolate", height: "100%" }}>{children}</div>
      {/*/* * The isolation creates a stacking context, i.e. the z-index of  children won't mess up with its siblings. */}
      <div id="radix-themed-portal" style={{ isolation: "isolate" }}></div>
    </StyledTheme>
  );
}

export { ICTheme };
